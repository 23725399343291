import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi, SideBarDef } from "ag-grid-enterprise";
import { Dialog } from "@mui/material";
import { fontSize, fontWeight } from "@mui/system";
import {
  Box,
  ButtonPrimary1,
  HStack,
  IconCTAButton,
  VStack,
} from "../../components/utils";
import SearchInput from "../../components/shared/SearchInput";
import { ReportNames } from "./Reports";
import { sortComparator } from "../../utils/agGridUtils";
import ReportDialog from "./dialogs/ReportDialog";
import PoolStatementDialog from "./dialogs/PoolStatementDialog";
import SH6ReportDialog from "../esopOverview/SH6ReportDialog";
import FinancialReportDialog from "../esopOverview/FinancialReportDialog";
import GenericTableHeader from "../../shared/GenericTableHeader";

const ReportsAgGridTable = ({
  reportsData,
}: {
  reportsData: ReportNames[];
}) => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    data: any;
  }>({ open: false, data: {} });
  const gridApi = useRef<GridApi | any>(null);
  const generateButton = (params: any) => (
    <ButtonPrimary1
      onClick={() => {
        setDialog({ open: true, data: params.data });
      }}
    >
      Generate Report
    </ButtonPrimary1>
  );

  useEffect(() => {
    if (reportsData !== undefined) {
      setFilteredRowData(reportsData);
    }
  }, [reportsData]);

  const sortComparator = (
    valueA: any,
    valueB: any,
    nodeA: any,
    nodeB: any,
    isInverted: any
  ) => {
    if (valueA === valueB) return 0;
    return valueA > valueB ? 1 : -1;
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "NAME OF DOCUMENT",
      field: "name",
      cellStyle: (params: any) => ({
        fontWeight: 500,
      }),
      flex: 2,
      filter: "agTextColumnFilter",

      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "DESCRIPTION OF DOCUMENT",
      field: "description",
      flex: 6.5,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "GENERATE REPORT",
      field: "actions",
      cellRendererParams: ({ value }: { value: any }) => value,
      cellRenderer: generateButton,
      flex: 1.5,
    },
  ];

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      wrapText: true,
      flex: 1,
      autoHeight: true,
      initialWidth: 100,
      suppressMenu: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      cellClass: "multiline",
      comparator: sortComparator,
      minWidth: 100,
      resizable: true,
    }),
    []
  );

  const sideBar = useMemo<SideBarDef>(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          minWidth: 225,
          width: 225,
          maxWidth: 225,
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          minWidth: 180,
          maxWidth: 400,
          width: 250,
        },
      ],
    }),
    []
  );

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    let filterModel = grid.api.getFilterModel();

    const isOnlyEmptySetFilters =
      Object.keys(filterModel).length > 0 &&
      Object.values(filterModel).every(
        (filter: any) =>
          filter.filterType === "set" &&
          Array.isArray(filter.values) &&
          filter.values.length === 0
      );

    if (isOnlyEmptySetFilters) {
      grid.api.setFilterModel(null);
      filterModel = {};
    }

    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const onGridReady = (params: any) => {
    gridApi.current = params;
  };

  return (
    <VStack className="w-full h-full">
      <HStack className="items-center justify-between gap-4">
        <GenericTableHeader
          heading={"Reports"}
          subHeading={""}
          additionalInfo={<>{`${filteredRowData?.length} reports`}</>}
        ></GenericTableHeader>
        <HStack className="gap-4">
          <HStack>
            <SearchInput
              className=""
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
          </HStack>
          {/* <QuickSearch
          className="px-2"
          placeholder="Search"
          onChange={(e) => {
            gridApi.current.setQuickFilter(e.target.value);
          }}
        /> */}
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4  font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${reportsData.length * 60}px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto bg-black ag-theme-material"
        >
          <AgGridReact
            onFilterChanged={onAgGridFilterChanged}
            alwaysShowHorizontalScroll
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            alwaysMultiSort
            onGridReady={onGridReady}
            sideBar={sideBar}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={reportsData}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={true}
            columnDefs={columnDefs}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            pagination={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;   margin-top: 50px;">No Rows To Show</span>'
            }
          ></AgGridReact>
        </Box>
      </HStack>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false, data: undefined })}
        maxWidth="lg"
      >
        {dialog.data?.name === "Pool Statement" ? (
          <div className="w-[400px] mx-auto bg-white rounded-lg  min-h-min">
            <PoolStatementDialog
              data={dialog.data}
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        ) : dialog.data?.name === "SH-6 Report" ? (
          <div className="mx-auto bg-white rounded-lg min-w-min min-h-min">
            <SH6ReportDialog
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        ) : (
          <div className="w-[800px] mx-auto bg-white rounded-lg  min-h-min">
            <ReportDialog
              data={dialog.data}
              onClose={() => setDialog({ open: false, data: undefined })}
            />
          </div>
        )}
      </Dialog>
    </VStack>
  );
};

export default ReportsAgGridTable;
