import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  ButtonPrimary,
  HStack,
  isAdminViewer,
  isEsopViewer,
  VStack,
} from "../../components/utils";
import { useEsopPlans, useGrants } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import CardPend from "../../shared/card-pend";
import CardPlan from "../../shared/card-plan";
import CardPool from "../../shared/card-pool";
import { Dropdown } from "../../utils/interfaces/BCHeader";
import GrantsTable from "../esopOverview/GrantsTable";
import VestingEvents from "./VestingEvents";

import CardTransaction from "../../shared/card-transaction";
import { usePermissionStore } from "../../store/permissionStore";
import { determineUserAccessToResource } from "../../utils/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import SearchAutoComplete from "../../components/shared/SearchAutoComplete";
import dropdown from "../../shared/dropdown";

function AllPlans() {
  const navigate = useNavigate();
  const { data: grants } = useGrants();
  const { data: esopTableDtoList } = useEsopPlans();
  const plans = esopTableDtoList || [];
  const [selectedPlan, setSelectedPlan] = useState<Dropdown>({
    name: "All Plans",
    id: "Total",
  });
  const { permission } = usePermissionStore();
  const planDropdown: Dropdown[] = [];
  const [lastPage, setLastPage] = useState<string>();
  const isUserAdminViewer = isAdminViewer();
  const isUserEsopViewer = isEsopViewer();

  plans.forEach((plan, index) => {
    planDropdown.push({ name: plan.planName, id: plan.esopPlanId });
  });
  function handleAction() {
    if (
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.OptionsPlan,
        DefaultAction.Create
      )
    ) {
      navigate("/options/plan/add");
    }
  }

  planDropdown.unshift(selectedPlan);
  function handlePlan(e: React.ChangeEvent<HTMLSelectElement>) {
    const id = e.target.value;
    if (id === "Total") {
      navigate("/options/allPlans");
    } else navigate(`/options/planView/${id}`);
  }
  const { isMobile } = useIsMobile();
  function handleSelectOptions(selectedPlan: any) {
    if (selectedPlan.id === "Total") {
      navigate("/options/allPlans");
    } else {
      navigate(`/options/planView/${selectedPlan.id}`);
    }
  }
  return (
    <VStack className="gap-4">
      <HStack className="justify-between">
        <BCHeader
          className="items-baseline "
          bcTitle="Equity Plans & Grants"
          bcSubTitle={
            selectedPlan?.name === "Equity Plans & Grants"
              ? ""
              : selectedPlan?.name ?? ""
          }
          onClick={handleAction}
          selectOption={handlePlan}
          selectedValue={selectedPlan}
          dropdown={planDropdown}
          bcDescription="Explore comprehensive details of your equity plans, track grant allocations, and monitor plan utilizations across the organization."
        />
        <Box className={`flex flex-row ${isMobile ? "mb-4 gap-2 " : ""}`}>
          {planDropdown && planDropdown.length > 0 ? (
            <Box className="bg-white rounded w-48 h-8">
              <SearchAutoComplete
                data={planDropdown}
                getSelectedValue={() => selectedPlan}
                selectedOption={selectedPlan! as Dropdown}
                field={"name"}
                selectOption={(selectedPlan: Dropdown) =>
                  handleSelectOptions(selectedPlan)
                }
              />
            </Box>
          ) : (
            ""
          )}
          <Box>
            <ButtonPrimary
              className={`px-5 py-1 ${isMobile ? "" : "ml-4"} h-8`}
              onClick={handleAction}
              disabled={isUserAdminViewer || isUserEsopViewer}
            >
              Create ESOP Plan
            </ButtonPrimary>
          </Box>
        </Box>
      </HStack>
      <Box className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
        <CardPlan />
        <CardPool />
        <CardTransaction />
      </Box>
      <Box
        className={`grid gap-6 overflow-auto  ${
          isMobile ? "grid-cols-1" : "grid-cols-2"
        }`}
      >
        <VestingEvents />
        <CardPend></CardPend>
      </Box>
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} mb-10`}>
        {grants && <GrantsTable />}
      </Box>
    </VStack>
  );
}

export default AllPlans;
